import {getParam} from "./getParam";

export const updateCart = () => {

  const uid = getParam('uid')

  const cart = JSON.parse(localStorage.getItem(`${uid}-cart`)) || [];
  console.log(cart.length);
  const totalQuantity = cart.reduce((total, item) => total + item.quantity, 0);
  $('.cart_count').text(totalQuantity || 0);

  if(cart.length === 0) {
    $('.cart-items').hide()
    $('.cart-null').show()
  }


  if (document.cookie.split(';').some((item) => item.trim().startsWith(`hideGdpr-${uid}=`))) {
    // document.getElementById('gdpr').style.display = 'none';
  } else {
    const cookieBlock = `
    <section id="gdpr">
  <div class="gdpr__box">
    <div class="gdpr__container">
      <div class="gdpr__text"> We use cookies (and other similar technologies) for many purposes, including to improve
        your experience on our site and for ads and analytics. Click "Accept all" to accept these uses. Read more in our
        <a data-page="privacy-policy" class="get_page">Cookie Policy</a>.
      </div>
      <div class="gdpr__buttons"><span class="gdpr__close" style=""> Reject all </span> <span
        class="gdpr__close gdpr__dark"> Accept all </span></div>
    </div>
  </div>
</section>`

    $('body').append(cookieBlock);

  }

  let closeButtons = document.querySelectorAll('.gdpr__close');

  closeButtons.forEach(function(button) {
    button.addEventListener('click', function() {
      document.getElementById('gdpr').style.display = 'none';

      let d = new Date();
      d.setTime(d.getTime() + (30*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = `hideGdpr-${uid}=true; ${expires}- ;path=/`;
    });
  });
}
