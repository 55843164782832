export const subscribeForm = () => {
  $('.subscribe-mail .ft-form').validate({
    submitHandler: function (form) {
      $('.subscribe-mail.subscribe-block').addClass('active')

      setTimeout(function () {
        $('.subscribe-mail.subscribe-block form').hide()
        $('.subscribe-mail.subscribe-block').html(`
          <div class="subscribe__text">
            Subscription Confirmed!
            </div>
        `).removeClass('active')


      }, 1000);

    },
    success: () => {
    },
    errorPlacement: function (error, element) {
      const _element = $(element);
      if (_element.hasClass('error')) {
        _element.closest('div[class^="form-holder"]').addClass('has-error').removeClass('accept');
        _element.addClass('error');
      } else {
        _element.closest('div[class^="form-holder"]').removeClass('has-error').addClass('accept');
        _element.removeClass('error');
      }
    },
    rules: {
      email: {
        required: true,
        email: true
      },

    },
  });
  $('.subscribe-phone .ft-form').validate({
    submitHandler: function (form) {
      $('.subscribe-phone.subscribe-block').addClass('active')

      setTimeout(function () {
        $('.subscribe-phone.subscribe-block form').hide()
        $('.subscribe-phone.subscribe-block').html(`
          <div class="subscribe__text">
            Subscription Confirmed!
          </div>
        `).removeClass('active')
      }, 1000);
    },
    success: () => {},
    errorPlacement: function (error, element) {
      const _element = $(element);
      if (_element.hasClass('error')) {
        _element.closest('div[class^="form-holder"]').addClass('has-error').removeClass('accept');
        _element.addClass('error');
      } else {
        _element.closest('div[class^="form-holder"]').removeClass('has-error').addClass('accept');
        _element.removeClass('error');
      }
    },
    rules: {
      phone: {
        required: true,
        digits: true,
        maxlength: 20
      },
    },
  });
  $('.subscribe-phone .ft-form__input[name="phone"]')
  .attr('maxlength', 20)
  .on('keypress', function (e) {
    if (e.which < 48 || e.which > 57) { 
      e.preventDefault();
    }
  })
  .on('input', function () {
    if (this.value.length > 20) {
      this.value = this.value.slice(0, 20);
    }
  });



  $('.contact-form form').validate({
    submitHandler: function (form) {
        if ($('#contactCheck1').is(':checked') && $('#contactCheck2').is(':checked') && $('#contactCheck3').is(':checked') && $('#contactCheck4').is(':checked')) {
          $('.contact-form__result').html(`
              Thank you for your message. It has been sent.
          `);
        }
    },
    success: () => {
    },
    errorPlacement: function (error, element) {
        const _element = $(element);
        if (_element.hasClass('error')) {
            _element.closest('div[class^="form-holder"]').addClass('has-error').removeClass('accept');
            _element.addClass('error');
        } else {
            _element.closest('div[class^="form-holder"]').removeClass('has-error').addClass('accept');
            _element.removeClass('error');
        }
    },
    rules: {
        contactFirstName: {
            required: true
        },
        contactLastName: {
            required: true
        },
        contactPhone: {
            required: true
        },
        contactEmail: {
            required: true,
            email: true
        },
        contactSelect: {
          required: true,
        },
        contactMessage: {
            required: true
        },
        contactCheck1: {
          required: true
        },
        contactCheck2: {
          required: true
        },
        contactCheck3: {
          required: true
        },
        contactCheck4: {
          required: true
        }
    }
  });

  $('.contact-form form input[name="phone"]')
  .attr('maxlength', 20)
  .on('keypress', function (e) {
    if (e.which < 48 || e.which > 57) { 
      e.preventDefault();
    }
  })
  .on('input', function () {
    if (this.value.length > 20) {
      this.value = this.value.slice(0, 20);
    }
  });
}
