import { getCurrentPath } from './getCurrentPath';
import { getParam } from "./getParam";

export const getPage = (name) => {
  const path = getCurrentPath();
  const uid = getParam('uid')

  switch (name) {
    case 'index':
      return `${path}/?uid=${uid}`;
    case 'confirmation':
      return `${path}/confirmation.html?uid=${uid}`;
    case 'order':
      return `${path}/order.html?uid=${uid}`;
    case 'product':
      return `${path}/product.html?uid=${uid}`;
    case 'cart':
      return `${path}/cart.html?uid=${uid}`;
    case 'shop':
      return `${path}/shop.html?uid=${uid}`;
    case 'contacts':
      return `${path}/contacts.html?uid=${uid}`;
    case 'terms':
      return `${path}/terms.html?uid=${uid}`;
    case 'privacy-policy':
      return `${path}/privacy-policy.html?uid=${uid}`;
    case 'cancellation-request':
      return `${path}/cancellation-request.html?uid=${uid}`;
  }
};





