import { getParam } from "./getParam";

export function getState(callback) {

  let url = '/ajax/state'
  const uid = getParam('uid')

  if (uid) {
    url += '?uid=' + uid
  }

  $.ajax({
    url: url,
    type: "GET",
    success: function (data) {
      callback(data);
    },
    error: function (error) {
      console.error("Error fetching state:", error);
    },
  });
}
