export const setContacts = (data) => {
  const str_phones = data.phone || "";
  const arr_phones = str_phones ? str_phones.split(" / ") : [];

  let header_phone = "";
  let footer_phone = "";

  const countryFlags = {
    '+44': ['uk.svg', 'eu.svg'],
    '+1': ['us.svg'],
    '+61': ['au.svg'],
    '+64': ['nz.svg'],
    '+351': ['pt.svg'],
    '+55': ['br.svg'],
    '+52': ['mx.svg'],
  };

  arr_phones.forEach(num => {
    const countryCodeMatch = num.match(/^\+\d+/);
    const countryCode = countryCodeMatch ? countryCodeMatch[0] : null;
    const flags = countryCode ? (countryFlags[countryCode] || ['uk.svg']) : ['uk.svg'];
  
    let flagImages = flags.map(flag => `<img src="/static/common/shared/flag/${flag}" class="phone__flag">`).join(' ');
  
    header_phone += `
      <div class="subheader-soc_itm">
        ${flagImages}
        <a href="tel:${num}">
          ${num}
        </a>
      </div>
    `;
  });

  arr_phones.forEach(num => {
    footer_phone += `
      <a class="phone__line" href="tel:${num}">${num}</a>
    `;
  });

  $(".subheader-soc_phone").html(header_phone);
  $(".footer_phone>span").html(footer_phone);
  $(".contact-description>span").html(footer_phone);
  $(".privacy-phone").html(footer_phone);



  


  $('.email__line').html(data.email);
  $('.subheader-soc .email__line').attr('href', 'mailto:' + data.email);

  $('.address__line').html(data.address)
  $('.companyName').html(data.companyName)
  $('.headerTitle').html(data.headerTitle)

  $('.host-name').html(window.location.hostname)
}
