import { getPage } from "./getPage";

export const getRout = () => {
  const $loader = $('.popup-loading-wrapper');
  $loader.show();

  $('.get_page').click(function (e) {
    const $target = $(e.currentTarget);
    const page = $target.data('page');

    window.location.href = getPage (page)
  });
}
